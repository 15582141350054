// @ts-nocheck
import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles'
// import { MetaMaskUIProvider } from '@metamask/sdk-react-ui'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import bgImg from '../assets/images/bgImg.png'
import { isMobile } from 'react-device-detect'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import AddressClaimModal from '../components/claim/AddressClaimModal'
import Header from '../components/Header'
import Polling from '../components/Header/Polling'
import URLWarning from '../components/Header/URLWarning'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import { ApplicationModal } from '../state/application/actions'
import { useModalOpen, useToggleModal } from '../state/application/hooks'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import AddLiquidity from './AddLiquidity'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity
} from './AddLiquidity/redirects'
// import Earn from './Earn'
// import Manage from './Earn/Manage'
// import MigrateV1 from './MigrateV1'
// import MigrateV1Exchange from './MigrateV1/MigrateV1Exchange'
// import RemoveV1Exchange from './MigrateV1/RemoveV1Exchange'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
import Farms from './Farms'
import RemoveLiquidity from './RemoveLiquidity'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import Swap from './Swap'
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
import Drawer from '../components/Drawer'
import BottomTabs from '../components/BottomTabs'
// import upperadvertisement from '../assets/images/upperadvertisement.png'
// import loweradvertisement from '../assets/images/loweradvertisement.png'
// import Vote from './Vote'
// import VotePage from './Vote/VotePage'
// import { left } from '@popperjs/core'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;
  background: linear-gradient(149.65deg, #0f0f0f -2.81%, #0a0b49 75.27%);
  padding: 10px;
  z-index: 1;
`

// const Marginer = styled.div`
//   margin-top: 5rem;
// `

function TopLevelModals() {
  const open = useModalOpen(ApplicationModal.ADDRESS_CLAIM)
  const toggle = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
  return <AddressClaimModal isOpen={open} onDismiss={toggle} />
}

function AllRoutes() {
  return (
    <Switch>
      <Route exact strict path="/swap" component={Swap} />
      {/* <Route exact strict path="/claim" component={OpenClaimAddressModalAndRedirectToSwap} /> */}
      <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
      <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
      <Route exact strict path="/find" component={PoolFinder} />
      <Route exact strict path="/pool" component={Pool} />
      <Route exact strict path="/farm" component={Farms} />
      {/* <Route exact strict path="/uni" component={Earn} /> */}
      {/* <Route exact strict path="/vote" component={Vote} /> */}
      <Route exact strict path="/create" component={RedirectToAddLiquidity} />
      <Route exact path="/add" component={AddLiquidity} />
      <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
      <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
      <Route exact path="/create" component={AddLiquidity} />
      <Route exact path="/create/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
      <Route exact path="/create/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
      {/* <Route exact strict path="/remove/v1/:address" component={RemoveV1Exchange} /> */}
      <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
      <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
      {/* <Route exact strict path="/migrate/v1" component={MigrateV1} /> */}
      {/* <Route exact strict path="/migrate/v1/:address" component={MigrateV1Exchange} /> */}
      {/* <Route exact strict path="/uni/:currencyIdA/:currencyIdB" component={Manage} /> */}
      {/* <Route exact strict path="/vote/:id" component={VotePage} /> */}
      <Route component={RedirectPathToSwapOnly} />
    </Switch>
  )
}
export default function App() {
  const darkTheme = createTheme({
    palette: {
      mode: 'dark'
    }
  })
  return (
    <ThemeProvider theme={darkTheme}>
      {/* <MetaMaskUIProvider
        sdkOptions={{
          useDeeplink: true,
          communicationLayerPreference: 'SOCKET',
          dappMetadata: {
            name: 'Chimp Exchange',
            url: window.location.href
          }
          // Other options.
        }}
      > */}
      <Suspense fallback={null}>
        <Route component={GoogleAnalyticsReporter} />
        <Route component={DarkModeQueryParamReader} />
        <AppWrapper>
          <URLWarning />
          {/* <HeaderWrapper>
          <Header />
        </HeaderWrapper> */}
          {/* <div className="center" style={{ paddingTop: '50px' }}> */}
          {/* <img
          src={upperadvertisement}
          className="center"
          style={{
            height: '150px',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: '50px',
            width: '100%',
            objectFit: 'contain'
          }}
        /> */}
          {/* </div> */}
          <BodyWrapper>
            <Popups />
            <Polling />
            <TopLevelModals />
            <Web3ReactManager>
              {isMobile ? (
                <>
                  <Header />
                  <Box
                    component="main"
                    sx={{
                      flexGrow: 1,
                      marginBottom: '100px',
                      padding: '10px',
                      width: '100%',
                      background: '#000000',
                      borderRadius: '37px',
                      minHeight: '100vh',
                      backgroundImage: `url(${bgImg})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat'
                    }}
                  >
                    <AllRoutes />
                  </Box>
                  <BottomTabs />
                </>
              ) : (
                <Drawer>
                  <AllRoutes />
                </Drawer>
              )}
            </Web3ReactManager>
            {/* <div style={{ textAlign: 'center', paddingTop: '20px' }}> */}
            {/* <img
            src={loweradvertisement}
            style={{
              height: '120px',
              marginLeft: 'auto',
              marginRight: 'auto',
              paddingTop: '20px',
              width: '100%',
              objectFit: 'contain'
            }}
          /> */}
            {/* </div> */}
            {/* <Marginer /> */}
          </BodyWrapper>
        </AppWrapper>
      </Suspense>
      {/* </MetaMaskUIProvider> */}
    </ThemeProvider>
  )
}
